<script setup>
import getTheAppQrCode from '@/assets/images/homepage/get-the-app-qr.svg';
import getTheAppQrCodePurple from '@/assets/images/homepage/get-the-app-qr-purple.svg';

const GET_THE_APP_URLS = {
  branch: 'https://w5az.app.link/xZ2ty1CTQnb',
  appsflyer: 'https://hostelworld.onelink.me/IdiK/i7rs0hx5',
};

const { $tracking } = useNuxtApp();
const testingStore = useTestingStore();

const getTheAppUrl = computed(() => {
  return testingStore.hasEnabledFeature('web_linksToAppsflyer') ? GET_THE_APP_URLS.appsflyer : GET_THE_APP_URLS.branch;
});

const getTheAppSvg = computed(() => {
  return testingStore.hasEnabledFeature('web_linksToAppsflyer')
    ? 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_72/v1712750845/pwa/web-to-apps-qrcode.svg'
    : getTheAppQrCode;
});

const getTheAppSvgPurple = computed(() => {
  return testingStore.hasEnabledFeature('web_linksToAppsflyer')
    ? 'https://a.hwstatic.com/image/upload/f_auto,q_auto,h_124/v1712750845/pwa/web-to-apps-qrcode.svg'
    : getTheAppQrCodePurple;
});

// ### METHODS
function onQRButtonClick() {
  const ref = document.getElementById('download-section');
  if (ref && ref?.getBoundingClientRect) {
    scrollTo(0, ref.getBoundingClientRect().top);
  }
}
</script>

<template>
  <div>
    <nuxt-link
      class="badge-wrapper"
      :href="getTheAppUrl"
      rel="noopener noreferrer"
      target="_blank"
      :title="$t('t_GETTHEAPP')"
      @click="$tracking.onAppDownloadClick()"
    >
      <div
        role="dialog"
        class="badge"
        :aria-label="$t('t_GETTHEAPP')"
      >
        <img
          :alt="`${$t('t_GETTHEAPP')} icon`"
          width="24"
          height="24"
          loading="lazy"
          src="https://a.hwstatic.com/image/upload/v1644942643/pwa/sp/hw-icon.svg"
        />
        {{ $t('t_GETTHEAPP') }}
      </div>
    </nuxt-link>

    <div
      role="dialog"
      class="gta-fixed-wrapper"
      :aria-label="$t('t_GETTHEAPP')"
      @click="onQRButtonClick"
    >
      <img
        :alt="`${$t('t_GETTHEAPP')} QR`"
        width="72"
        height="72"
        class="qr-white"
        loading="lazy"
        :src="getTheAppSvg"
      />
      <img
        :alt="`${$t('t_GETTHEAPP')} QR`"
        width="124"
        height="124"
        class="qr-purple"
        loading="lazy"
        :src="getTheAppSvgPurple"
      />
      <div class="text">
        {{ $t('t_GETTHEAPP') }}
      </div>
    </div>

    <div class="badge-placeholder" />
  </div>
</template>

<style lang="scss" scoped>
.badge {
  // positioning
  position: fixed;
  bottom: $wds-spacing-m;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  // layout
  display: flex;
  align-items: center;
  padding: wds-rem(12px);

  // Colors
  color: $wds-color-teal-light;
  background-color: $wds-color-purple;
  border-radius: $wds-border-radius-m;
  box-shadow: $wds-shadow-light-xl;

  // Font
  @include title-6-bld;

  line-height: wds-rem(20px);

  & img {
    margin-right: $wds-spacing-s;
  }
}

.gta-fixed-wrapper {
  // positioning
  position: fixed;
  bottom: $wds-spacing-m;
  right: $wds-spacing-m;
  z-index: $wds-z-index-overlay;

  // layout
  max-width: wds-rem(140px);
  width: fit-content;
  height: wds-rem(88px);
  padding: $wds-spacing-s;
  box-shadow: $wds-shadow-light-xl;
  background-color: $wds-color-purple;
  border-radius: $wds-border-radius-m;
  color: $wds-color-teal-light;

  // Font
  @include title-6-bld;

  line-height: wds-rem(20px);

  // Animation
  display: none;
  transform: translateY(120%);
  animation: appear 0.3s 0.5s linear forwards;

  @keyframes appear {
    0% {
      transform: translateY(120%);
    }

    100% {
      transform: translateY(0);
    }
  }

  svg {
    fill: $wds-color-white;
    stroke: $wds-color-white;
    height: 100%;
  }

  .text {
    margin-left: $wds-spacing-s;
    display: flex;
    align-items: center;
    width: wds-rem(44px);
    word-spacing: wds-rem(44px);
  }

  .qr-white {
    width: wds-rem(72px);
  }

  .qr-purple {
    display: none;
  }

  &:hover {
    height: wds-rem(140px);
    cursor: none;

    .qr-white,
    .text {
      display: none;
    }

    .qr-purple {
      display: inline-block;
    }
  }
}

@include desktop {
  .badge {
    display: none;
  }

  .gta-fixed-wrapper {
    display: flex;
  }
}
</style>
